import { Container, H2, H3, Paragraph, XStack, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
import section7_1 from 'public/img/fof-section7.1.png';
import section7_2 from 'public/img/fof-section7.2.png';

const Section7 = () => {
  const media = useMedia();

  return (
    <YStack borderBottomWidth={1} borderColor="$border">
      <Container>
        <YStack $md={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14" id="harvesting">
          {/* Top column (right on desktop because of flex-order) */}
          <YStack
            className={media.md ? 'flex-order-2' : 'flex-order-1'}
            flex={1}
            $md={{ flexBasis: 0, justifyContent: 'center' }}
          >
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Automate your harvest workflows
            </H2>

            <H3 fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" mt="$8">
              Streamline task allocation for your harvest manager
            </H3>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Harvesting lists are automatically generated by aggregating all the pre orders for a given day. A manager
              can easily allocate harvest tasks to different team members and even define what field they should harvest
              from. Harvest task allocation can also be automated using the powerful task scheduling functionality.
            </Paragraph>

            <H3 fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" mt="$8">
              Mobile support for your field teams
            </H3>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              The Holo mobile app makes it very efficient for your harvesting teams to track and manage the tasks that
              are allocated to them. For each harvest task, it is easy to record the field that was used by using the
              GPS guided lookup, and once the task is marked as completed, the time, person and LOT number are
              automatically recorded. With the integrated rugged mobile label printer, your team members can also print
              labels that contain crop name, qty, time, field name, LOT number and the person that performed the
              harvesting. The labels also contain QR codes that your packing house team can scan in order to record this
              information for each line item that they pack.
            </Paragraph>
          </YStack>

          {/* Bottom column (left on desktop because of flex-order) */}
          <YStack
            className={media.md ? 'flex-order-1' : 'flex-order-2'}
            flex={1}
            $md={{ flexBasis: 0 }}
            justifyContent="center"
          >
            {/* Images */}
            <YStack>
              <XStack mt="$4" space="$4">
                <YStack flex={1}>
                  <Image
                    src={section7_1}
                    alt="Harvesting on desktop"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
                <YStack flex={1}>
                  <Image
                    src={section7_2}
                    alt="Harvesting on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section7;
