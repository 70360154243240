import { Container, H2, H3, Paragraph, XStack, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
import section8_1 from 'public/img/fof-section8.1.png';
import section8_2 from 'public/img/fof-section8.2.png';

const Section8 = () => {
  const media = useMedia();

  return (
    <Container>
      <YStack $md={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14" id="soil-health">
        {/* Top column (right on desktop because of flex-order) */}
        <YStack
          className={media.md ? 'flex-order-2' : 'flex-order-1'}
          flex={1}
          $md={{ flexBasis: 0, justifyContent: 'center' }}
        >
          <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
            Get on top of your soil health
          </H2>

          <H3 fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" mt="$8">
            A comprehensive dashboard for soil tests
          </H3>
          <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
            With Holo's soil test dashboard, you can easily get a comprehensive overview of your soil test data overlaid
            on top of your fields. Switch between layers for different soil test parameters to view your fields
            color-coded according to the data value for each field. You can also easily pull up your planting records
            within the same view to compare trends in your soil test data with what you have planted over time in that
            field. It is quick to bulk upload all your historic soil tests to get started, but we will also work on
            integration with leading soil test labs to enable automatic syncing of soil test results.
          </Paragraph>

          <H3 fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" mt="$8">
            App for soil sampling
          </H3>
          <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
            Our mobile app will include functionality that will make it super simple for you and your staff to take soil
            samples with speed and accuracy without relying on expensive service providers. With the app, you can record
            the time and GPS location for each core that you collect and automatically print labels for composite
            samples as well as the lab order forms. This will drastically improve the accuracy of your soil data as you
            can collect soil samples with better temporal and spatial precision and consistency.
          </Paragraph>
        </YStack>

        {/* Bottom column (left on desktop because of flex-order) */}
        <YStack
          className={media.md ? 'flex-order-1' : 'flex-order-2'}
          flex={1}
          $md={{ flexBasis: 0 }}
          justifyContent="center"
        >
          {/* Images */}
          <YStack>
            <XStack mt="$4" space="$4">
              <YStack flex={1}>
                <Image
                  src={section8_1}
                  alt="Harvesting on desktop"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </YStack>
              <YStack flex={1}>
                <Image
                  src={section8_2}
                  alt="Harvesting on mobile"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </YStack>
            </XStack>
          </YStack>
        </YStack>
      </YStack>
    </Container>
  );
};

export default Section8;
