import { Container, H2, Paragraph, YStack } from '@holo/ui';
import Image from 'next/image';
import section5 from 'public/img/fof-section5.png';

const Section5 = () => {
  return (
    <Container>
      <YStack $sm={{ flexDirection: 'row', pt: '$18', pb: '$30' }} gap="$8" pb="$14" id="crop-plans">
        <YStack flex={1} $sm={{ flexBasis: 0, justifyContent: 'center' }}>
          <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
            Make you crop plans available for future sale
          </H2>

          <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
            Once you have your crop plan setup, you can just hit the share button and send the link to all your
            wholesale customers so that they can see what you are planning to grow in the upcoming season. In our next
            version, customers will also be able to request pre purchase agreements where they can specify the crops,
            quantity and time spans that they are interested in and you can reply to their bid by specifying your prices
            and availability.
          </Paragraph>
        </YStack>

        <YStack flex={1} $sm={{ flexBasis: 0 }}>
          <Image
            src={section5}
            priority
            loading="eager"
            alt="Graphic of crop planning"
            style={{
              width: 'auto',
              height: 'auto',
            }}
          />
        </YStack>
      </YStack>
    </Container>
  );
};

export default Section5;
