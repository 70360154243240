import { Circle, Container, H2, Icon, Paragraph, Text, XStack, YStack } from '@holo/ui';
import Image from 'next/image';
import saleOrderPackingMobile from 'public/img/sale-order-packing-mobile.png';
import saleOrderPacking from 'public/img/sale-order-packing.png';

const listItems = [
  'Update line item qty and weights',
  'Automate label printing',
  'Automated customer email notification',
  'Logging for GAP compliance',
];

const Section9 = () => {
  return (
    <YStack backgroundColor="$accentPale" id="order-packing">
      <Container>
        <YStack space="$8" py="$14" $sm={{ flexDirection: 'row' }} $md={{ py: '$30' }}>
          {/* Top (right on desktop) */}
          <YStack $sm={{ flex: 1, flexBasis: 0 }}>
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Increase efficiency and accuracy for your packing house team
            </H2>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              The order packing functionality allows you easily update and confirm line item quantities and print
              labels. When the order is packed, a fulfillment email is automatically sent to the customers so that they
              know exactly what they will get. You can easily add field information for each line to allow for field
              traceability.
            </Paragraph>

            {/* Checkmark list */}
            <YStack mt="$8" space="$4" tag="ul">
              {listItems.map((listItem) => (
                <XStack key={listItem} ai="center" space="$2">
                  <Circle backgroundColor="$accent" width={24} height={24}>
                    <Icon icon="check" size={14} color="$base" />
                  </Circle>
                  <Text fontSize="$14" fontWeight="$semi-bold">
                    {listItem}
                  </Text>
                </XStack>
              ))}
            </YStack>
          </YStack>

          {/* Bottom (left on desktop) */}
          <YStack position="relative" justifyContent="center" $sm={{ flex: 1, flexBasis: 0 }}>
            <Image
              src={saleOrderPacking}
              alt="Storefront checkout on mobile"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Image
              src={saleOrderPackingMobile}
              alt="Crop planning map mobile"
              style={{
                width: '25vw',
                minWidth: 100,
                maxWidth: 178,
                height: 'auto',
                margin: '-30% 30% 0 auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section9;
