import { Circle, Container, H2, Icon, Paragraph, Text, XStack, YStack } from '@holo/ui';
import Image from 'next/image';
import saleStorefrontCartImage from 'public/img/sale-storefront-cart.png';
import saleStorefrontCheckoutImage from 'public/img/sale-storefront-checkout.png';
import saleStorefrontImage from 'public/img/sale-storefront.png';

const listItems = [
  'Retail and wholesale availability & prices',
  'Advanced CSA functionality',
  'Extensive discount options',
  'Delivery area definition',
  'Define pickup locations',
  'Cut-off times',
];

const Section4 = () => {
  return (
    <YStack borderBottomWidth={1} borderColor="$border" id="marketplace">
      <Container>
        <YStack $sm={{ flexDirection: 'row' }} $md={{ py: '$30' }} gap="$8" py="$14">
          {/* Left column */}
          <YStack mt="$8" flex={1} $sm={{ flexBasis: 0, justifyContent: 'center' }}>
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              A state of the art online store for both retail and wholesale customers
            </H2>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Built with the latest tech by world class e-commerce developers , this online store will make shopping a
              delightful experience for your customers. It supports separate prices and availability for both retail and
              wholesale customers, CSA subscriptions, discounts, easy reordering functionality, and it works great on
              both desktop and mobile devices.
            </Paragraph>
            {/* Checkmark list */}
            <YStack mt="$8" space="$4">
              {listItems.map((listItem) => (
                <XStack key={listItem} ai="center" space="$2">
                  <Circle backgroundColor="$accent" width={24} height={24}>
                    <Icon icon="check" size={14} color="$base" />
                  </Circle>
                  <Text fontSize="$14" fontWeight="$semi-bold">
                    {listItem}
                  </Text>
                </XStack>
              ))}
            </YStack>
          </YStack>
          {/* Right column */}
          <YStack flex={1} $sm={{ flexBasis: 0, justifyContent: 'center' }}>
            {/* Storefront images */}
            <YStack>
              <Image
                src={saleStorefrontImage}
                alt="Storefront on desktop"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
              <XStack mt="$4" space="$4">
                <YStack flex={1}>
                  <Image
                    src={saleStorefrontCartImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
                <YStack flex={1}>
                  <Image
                    src={saleStorefrontCheckoutImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section4;
