import { Container, H2, Paragraph, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
import reports from 'public/img/fof-reports.png';

const Section6 = () => {
  const media = useMedia();

  return (
    <YStack backgroundColor="$accentPale">
      <Container>
        <YStack $sm={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14" id="automate-reporting">
          <YStack
            className={media.sm ? 'flex-order-2' : 'flex-order-1'}
            flex={1}
            $sm={{ flexBasis: 0, justifyContent: 'center' }}
          >
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Automate reporting for GAP and Organic certifications
            </H2>

            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              By combining the data from all the digitized workflows, such as crop planning, tasks management,
              harvesting, order packing, and sales, we are able to automatically generate the reports needed for GAP and
              Organic certifications, saving you countless hours that you can spend on something way more fun. 
            </Paragraph>
          </YStack>

          <YStack className={media.sm ? 'flex-order-1' : 'flex-order-2'} flex={1} $sm={{ flexBasis: 0 }}>
            <Image
              src={reports}
              priority
              loading="eager"
              alt="Graphic of crop planning"
              style={{
                width: 'auto',
                height: 'auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section6;
