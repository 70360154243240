import { Circle, Container, H2, H3, Icon, Paragraph, Text, XStack, YStack } from '@holo/ui';
import { useMedia } from '@holo/ui/hooks';
import Image from 'next/image';
import saleDashboardMobileImage from 'public/img/sale-dashboard-mobile.png';
import saleDashboardImage from 'public/img/sale-dashboard.png';
import saleNotificationImage from 'public/img/sale-notification.png';

const listItems = [
  'Automatic logging of time',
  'Upload pictures',
  'Record notes and audio notes',
  'QR code based workflows',
  'Integration with rugged mobile label printer',
];

const Section3 = () => {
  const media = useMedia();

  return (
    <YStack borderBottomWidth={1} borderColor="$border" id="task-manager">
      <Container>
        <YStack $md={{ flexDirection: 'row', py: '$30' }} gap="$8" py="$14" id="task-manager">
          {/* Top column (right on desktop because of flex-order) */}
          <YStack
            className={media.md ? 'flex-order-2' : 'flex-order-1'}
            flex={1}
            $md={{ flexBasis: 0, justifyContent: 'center' }}
          >
            <H2 fontSize="$18" $sm={{ fontSize: '$24' }}>
              Supercharge your productivity with the farm task manager
            </H2>
            <H3 fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" mt="$8">
              Powerful scheduling
            </H3>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              Holo’s task management system allows you to schedule and manage all tasks at the farm, including all task
              and logging requirements for GAP and Organic certifications. You can schedule recurring tasks that are
              allocated to specific team members and even configure new tasks to be automatically scheduled based on
              events in e.g. crop plans or incoming orders.
            </Paragraph>
            <H3 fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" mt="$8">
              Easy to use mobile task manager for your team
            </H3>
            <Paragraph fontSize="$14" lineHeight="$2" color="$contrastPale" mt="$4">
              A modern and simple interface that makes your team members more efficient than ever. Your team can easily
              view, manage, and record, all their tasks on the go, upload pictures and record audio notes, and the
              integrated mobile label printer lets you design QR code based workflows.
            </Paragraph>
            {/* Checkmark list */}
            <YStack space="$4" mt="$8">
              {listItems.map((listItem) => (
                <XStack key={listItem} ai="center" space="$2">
                  <Circle backgroundColor="$accent" width={24} height={24}>
                    <Icon icon="check" size={14} color="$base" />
                  </Circle>
                  <Text fontSize="$14" fontWeight="$semi-bold">
                    {listItem}
                  </Text>
                </XStack>
              ))}
            </YStack>
          </YStack>
          {/* Bottom column (left on desktop because of flex-order) */}
          <YStack
            className={media.md ? 'flex-order-1' : 'flex-order-2'}
            flex={1}
            $md={{ flexBasis: 0 }}
            justifyContent="center"
          >
            {/* Dashboard images */}
            <YStack>
              <Image
                src={saleNotificationImage}
                alt="Storefront on desktop"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
              <XStack mt="$4" space="$4">
                <YStack flex={1}>
                  <Image
                    src={saleDashboardImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
                <YStack flex={1}>
                  <Image
                    src={saleDashboardMobileImage}
                    alt="Storefront checkout on mobile"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section3;
